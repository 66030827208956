<template>
  <div>
    <div class="row col-xl-12 mx-0 px-0">
      <div class="col-xl-8">
        <div class="card card-custom gutter-b">
          <div class="card-header card-header-tabs-line">
            <div class="card-title">
              <h3 class="card-label">
                {{
                  $t("NAVIGATION.COMMUNITY_OPTIONS.UPDATE_FORUM")
                }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <b-spinner
              v-if="loadingComplete == false"
              variant="primary"
              label="Spinning"
              class="card-loader"
            >
            </b-spinner>
            <form
              v-if="loadingComplete"
              class="form mt-10"
              id="manage-project-forum"
              @submit.prevent="submit"
            >
              <ErrorBox :errors="projectForumErrors" @clear_errors="clearErrors">
              </ErrorBox>
              <div class="row">
                <InputActivityTitle
                  class="col-xl-12 px-5 mb-10"
                  :model="$v.form.topic"
                  :submissionStates="submissionStates"
                  :label="$t('SETUP.COMMUNITY_OPTIONS.FORUM_TOPIC')"
                ></InputActivityTitle>
              </div>
              <div class="row">
                <label
                  id="input-forum-description-group_label"
                  for="input-forum-description"
                  class="d-block ml-5"
                >
                  {{ $t('SETUP.COMMUNITY_OPTIONS.FORUMS_DESCRIPTION') }}*
                </label>
                <textarea
                  id="input-forum-description"
                  v-model="$v.form.description.$model"
                  class="form-control form-control-solid mx-5 mb-10"
                  rows="6"
                >
                </textarea>
              </div>
              <div class="row">
                <InputGenericRadioOption
                  :model="$v.form.system_social_setting_id"
                  class="col-xl-4 px-5"
                  :submissionStates="submissionStates"
                  id="input-forum-social-setting"
                  :label="$t('SETUP.COMMUNITY_OPTIONS.FORUM_SOCIAL_SETTING')"
                  :error="$t('FORMS.ERROR.FORUM_SOCIAL_SETTING')"
                  :options="[
                    {
                      value: 1,
                      text: $t('FORMS.INPUTS.ACTIVITY_OPEN'),
                      tooltip: $t('ACTIVITY.TOOLTIPS.OPEN')
                    },
                    {
                      value: 2,
                      text: $t('FORMS.INPUTS.ACTIVITY_IN_SEGMENT'),
                      tooltip: $t('ACTIVITY.TOOLTIPS.IN_SEGMENT')
                    }
                  ]"
                >
                </InputGenericRadioOption>
              </div>
              <div class="row">
                <!-- Here be dragons - submissionStates manually set to false for now -->
                <InputAdvancedSegmentSelect
                  id="input-segments"
                  class="px-5 mb-5 align:right"
                  :submissionStates="{ submitting: false }"
                  children_key="project_segments"
                  label_key="name"
                  :options="projectSegmentations"
                  :initialSelection="initial_selection"
                  @children_changed="form.segments = $event"
                  @clear_segments="form.segments = []"
                  @initial_selection_changed="initial_selection = $event"
                >
                </InputAdvancedSegmentSelect>
              </div>
            </form>
          </div>
          <div class="card-footer d-flex justify-content-between my-0 py-4 w-100">
            <router-link
              tag="a"
              class="btn btn-secondary mr-2"
              to="/project/setup/community_options"
            >
              {{ $t("GENERAL.BUTTONS.CANCEL") }}
            </router-link>
            <button
              @click="onUpdateForum()"
              class="btn btn-primary mr-2"
            >
              {{ $t("GENERAL.BUTTONS.SAVE") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ErrorBox from "@/view/components/ErrorBox";

import {
  POPULATE_PROJECT_FORUM_INFO,
  UPDATE_PROJECT_FORUM,
  CLEAR_PROJECT_FORUM_ERRORS
} from "@/core/services/store/project/project_forums.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";

import InputActivityTitle from "@/view/components/form-inputs/ActivityTitle";
import InputGenericRadioOption from "@/view/components/form-inputs/GenericRadioOption";
import InputAdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";

export default {
  mixins: [validationMixin],

  name: "ManageProjectForum",

  components: {
    InputGenericRadioOption,
    InputActivityTitle,
    InputAdvancedSegmentSelect,
    ErrorBox
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 3
      },

      error: {
        active: false,
        message: ""
      },

      submissionStates: {
        submitting: false
      },

      initial_selection: [],

      form: {
        segments: [],
        topic: null,
        description: null,
        system_social_setting_id: 1
      }
    }
  },

  validations: {
    form: {
      segments: {},
      topic: {
        required
      },
      description: {
        required
      },
      system_social_setting_id: {
        required
      }
    }
  },

  mounted() {
    this.fetchProjectSegmentations();
    this.fetchProjectForumInfo();
  },

  methods: {
    prepopulateInputs() {
      this.form.topic = this.projectForum.topic;
      this.form.description = this.projectForum.description;
      this.form.system_social_setting_id = this.projectForum.system_social_setting_id;

      if (this.projectForum.project_segments.length > 0) {
        this.projectForum.project_segments.forEach(segment => {
          let seg = { parent: segment.project_segmentation_id, id: segment.id };
          this.initial_selection.push(seg);
        });
      }

      this.pageLoader.componentsCompleted++;
    },
    fetchProjectForumInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.$route.params.projectForum,
        params: {
          systemSocialSetting: "with",
          projectSegments: "with"
        }
      };

      this.$store
        .dispatch(POPULATE_PROJECT_FORUM_INFO, payload)
        .then(() => {
          this.prepopulateInputs();
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = 
            `${this.$t("SETUP.COMMUNITY_OPTIONS.LOAD_ERROR")}`;
        });
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false
        }
      };
     
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    onUpdateForum() {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        id: this.projectForum.id,
        data: this.form
      };

      this.$store
        .dispatch(UPDATE_PROJECT_FORUM, payload)
        .then(() => {
          this.makeToast("success");
          this.$router.push({ name: 'setup_community_options' }); 
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        })
    },
    makeToast(variant = null) {
      let msg = `${this.$t("SETUP.COMMUNITY_OPTIONS.FORUM_UPDATED")}`;
      this.$bvToast.toast(msg, {
        title: `${this.$t("GENERAL.FEEDBACK.SUCCESS")}`,
        variant: variant,
        solid: true
      });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_PROJECT_FORUM_ERRORS);
    },
  },
  computed: {
    ...mapGetters([
      "clientInfo",
      "projectInfo",
      "projectForum",
      "projectSegmentations",
      "projectForumErrors"
    ]),

    loadingComplete: function() {
     if (this.pageLoader.componentsCompleted < this.pageLoader.componentsRequired) {
       return false;
     } else {
       return true;
     }
    },
  }
}
</script>
